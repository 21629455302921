import {Rule} from 'antd/es/form';

import {Attachment, Device} from '~models';

export interface AddMediaContentForm {
  name: string;
  description?: string;
  attachment?: File & Attachment;
  devices: Device[];
}

export const initialValues: AddMediaContentForm = {
  name: '',
  devices: [],
};

export const nameRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];

export const attachmentRules: Rule[] = [
  {
    required: true,
    message: 'Không được để trống',
  },
];
