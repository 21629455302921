import {notification} from 'antd';
import {HttpStatusCode} from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';

import {ConnectStatus, DeviceFilterParams, PlayingStatus, Status} from '~models';
import {deleteDeviceAPI, getDevicesAPI} from '~services';
import {getSearchParamsWindow, isNullUndefined} from '~utils';

import {DeleteDevicePayload, GetDevicesPayload} from './action-models';

export const getDevicesThunk = createAsyncThunk(
  'DEVICE_SLICE/GET_DEVICES_THUNK',
  async ({setIsLoading, callback}: GetDevicesPayload) => {
    try {
      setIsLoading(true);
      const searchParams = getSearchParamsWindow<DeviceFilterParams>();

      const response = await getDevicesAPI({
        page: searchParams.current_page,
        per_page: searchParams.per_page,
        search: searchParams.search,
        must_play_plan: searchParams.must_play_plan,
        store: searchParams.store,
        area: searchParams.area,
        active: isNullUndefined(searchParams.connect)
          ? undefined
          : searchParams.connect === ConnectStatus.ONLINE,
        playing: isNullUndefined(searchParams.status)
          ? undefined
          : searchParams.status === PlayingStatus.PLAYING,
      });

      if (response.status === HttpStatusCode.Ok) {
        callback(response.data.data);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  },
);

export const deleteDeviceThunk = createAsyncThunk(
  'DEVICE_SLICE/DELETE_DEVICE_THUNK',
  async ({id, callback}: DeleteDevicePayload) => {
    try {
      const response = await deleteDeviceAPI(id);

      if (response.status === HttpStatusCode.Ok) {
        notification.success({
          message: 'Xoá thành công',
          description: 'Xoá thiết bị thành công.',
        });

        callback();
      }
    } catch (error: any) {
      notification.error({
        message: 'Xoá thất bại',
        description: error.response.data.message,
      });
    }
  },
);
