import {PlayingStatus, ConnectStatus} from '~models';

export const playingStatusOptions = [
  {value: PlayingStatus.PLAYING, label: 'Đang phát'},
  {value: PlayingStatus.STOP, label: 'Ngừng phát'},
];

export const connectStatusOptions = [
  {value: ConnectStatus.ONLINE, label: 'Online'},
  {value: ConnectStatus.OFFLINE, label: 'Offline'},
];
