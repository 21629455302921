import {Flex} from 'antd';
import {ChangeEvent} from 'react';

import {SearchIcon} from '~assets';
import {Input, SelectArea, Text} from '~components';
import {DeviceFilterParams} from '~models';

interface Props {
  searchParams: DeviceFilterParams;
  onSearchChange: (value: ChangeEvent<HTMLInputElement>) => void;
  setSearchParams: (values: DeviceFilterParams) => void;
}

const DeviceTableHeader = ({searchParams, onSearchChange, setSearchParams}: Props) => {
  return (
    <Flex className="devices__header" align="center">
      <Text className="devices__header-label">Thiết bị chiếu</Text>
      <Flex flex={1} gap={16}>
        <SelectArea selectedAreaId={searchParams.area} setSearchParams={setSearchParams} />
        <Input
          className="devices__header-input"
          placeholder="Nhập tên cửa hàng"
          allowClear
          prefix={<SearchIcon />}
          value={searchParams.search}
          onChange={onSearchChange}
        />
      </Flex>
    </Flex>
  );
};

export default DeviceTableHeader;
