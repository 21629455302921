import {IconProps} from '~models';

const EyeSquareIcon = ({
  fill = '#333',
  size = 29,
  bgFill = '#F5F5F5',
  stroke = '#EEE',
  ...props
}: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} fill={bgFill} />
    <g clipPath="url(#clip0_1_3736)">
      <path transform="translate(7 7)" fill="#fff" fillOpacity={0.01} d="M0 0H15V15H0z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.406 16.374c1.525 2.388 3.744 3.763 6.094 3.763 2.343 0 4.562-1.375 6.087-3.763.663-1.037.663-2.719 0-3.75-1.525-2.387-3.744-3.762-6.087-3.762-2.344 0-4.563 1.375-6.094 3.762-.663 1.038-.663 2.719 0 3.75zM9.2 13.13c1.35-2.118 3.28-3.33 5.3-3.33 2.018 0 3.95 1.212 5.3 3.33.468.732.468 2.007 0 2.738-1.35 2.119-3.282 3.331-5.3 3.331-2.02 0-3.95-1.212-5.3-3.331-.47-.731-.47-2.006 0-2.738zm2.593 1.37a2.707 2.707 0 005.413 0 2.707 2.707 0 00-5.413 0zm.938 0c0-.976.794-1.77 1.769-1.77.975 0 1.768.794 1.768 1.77 0 .974-.793 1.768-1.768 1.768a1.771 1.771 0 01-1.77-1.769z"
        fill={fill}
      />
    </g>
    <rect x={0.5} y={0.5} width={28} height={28} rx={3.5} stroke={stroke} />
    <defs>
      <clipPath id="clip0_1_3736">
        <path fill="#fff" transform="translate(7 7)" d="M0 0H15V15H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeSquareIcon;
