import {useCallback, useEffect} from 'react';

type OnTabClose = () => void;

export const useTabChange = (onTabClose: OnTabClose) => {
  const handleCloseTab = useCallback(
    (event: BeforeUnloadEvent) => {
      // event.preventDefault();
      onTabClose();
      return (event.returnValue = '');
    },
    [onTabClose],
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleCloseTab);

    return () => {
      window.removeEventListener('beforeunload', handleCloseTab);
    };
  }, [handleCloseTab]);
};
