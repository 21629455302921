import './styles.scss';

interface Props {
  uri?: string;
  file?: File;
  width?: string;
  height?: string;
}

const Video = ({uri, file, width = '100%', height = '100%'}: Props) => {
  const convertedURI = uri ? uri : URL.createObjectURL(file as File);

  return (
    <video width={width} height={height} controls>
      <source src={convertedURI} type="video/mp4" />
      <source src={convertedURI} type="video/ogg" />
      Không hỗ trợ định dạng
    </video>
  );
};

export default Video;
