import {Tag, Tooltip} from 'antd';
import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';
import {Link} from 'react-router-dom';

import {getSlug, showConfirmModal} from '~utils';
import {CommonObject, Device, MediaContent, Pagination, Store} from '~models';
import {DeleteIcon} from '~assets';
import {AppDispatch} from '~store';
import {deleteDeviceThunk} from '~reducers';

interface GetColumns {
  pagination: Pagination;
  searchTerm?: string;
  devicesLength: number;
  dispatch: AppDispatch;
  setSearchParams: (values: CommonObject<string>) => void;
  getDevices: () => void;
}

export const getColumns = ({
  pagination,
  searchTerm,
  devicesLength,
  dispatch,
  setSearchParams,
  getDevices,
}: GetColumns): ColumnsType<Device> => {
  const renderStoreName = (store: Store) => (
    <Highlighter searchWords={[`${searchTerm}`]} textToHighlight={store.name} sanitize={getSlug} />
  );

  const renderStatus = (active: boolean) => {
    return active ? <Tag color="success">Online</Tag> : <Tag color="error">Offline</Tag>;
  };

  const renderPlaying = (playing: boolean) => {
    return playing ? <Tag color="success">Đang phát</Tag> : <Tag color="error">Ngừng phát</Tag>;
  };

  const deleteDevice = (device: Device) => {
    showConfirmModal({
      title: 'Xoá thiết bị',
      content: 'Xác nhận xoá thiết bị khỏi danh sách này',
      onOk: () => {
        dispatch(
          deleteDeviceThunk({
            id: device.id,
            callback: () => {
              if (pagination.current_page > 1 && devicesLength === 1) {
                setSearchParams({current_page: 1});
              } else getDevices();
            },
          }),
        );
      },
    });
  };

  const renderMediaContent = (mediaContent: MediaContent) => {
    return (
      <Link
        to={`/media-content/add/${mediaContent?.id}`}
        state={{
          isPreviewed: true,
        }}>
        {mediaContent?.name}
      </Link>
    );
  };

  const renderActions = (_: unknown, device: Device) => {
    return (
      <Tooltip title="Xoá" placement="bottomRight">
        <DeleteIcon className="icon" fill="#C92127" onClick={() => deleteDevice(device)} />
      </Tooltip>
    );
  };

  const columns: ColumnsType<Device> = [
    {
      title: 'STT',
      width: 80,
      render: (_, __, index) => Number(pagination.from) + index,
    },
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      key: 'device_name',
      width: 200,
    },
    {
      title: 'Tên cửa hàng',
      dataIndex: 'store',
      key: 'store',
      width: 250,
      render: renderStoreName,
    },
    {
      title: 'Địa chỉ cửa hàng',
      dataIndex: 'store',
      key: 'address',
      width: 280,
      render: store => store?.address,
    },
    {
      title: 'Khu vực',
      dataIndex: 'area',
      key: 'area',
      width: 150,
      render: area => area?.name,
    },
    {
      title: 'Kết nối',
      dataIndex: 'active',
      key: 'active',
      width: 120,
      render: renderStatus,
    },
    {
      title: 'Trạng thái',
      dataIndex: 'playing',
      key: 'playing',
      width: 120,
      render: renderPlaying,
    },
    {
      title: 'Nội dung đang phát',
      dataIndex: 'in_device_plan',
      key: 'in_device_plan',
      width: 300,
      render: renderMediaContent,
    },
    {
      title: 'Nội dung cần phát',
      dataIndex: 'must_play_plan',
      key: 'must_play_plan',
      width: 300,
      render: renderMediaContent,
    },
    // {
    //   title: 'Thao tác',
    //   width: 100,
    //   render: renderActions,
    // },
  ];

  return columns;
};
