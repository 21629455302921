import {Device, DeviceStatistic} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse, SuccessResponse} from './service-models';

interface GetDevicesAPIParams {
  active?: boolean;
  store?: string;
  area?: string;
  playing?: boolean;
  must_play_plan?: string;
  search?: string;
  page?: number;
  per_page?: number;
}

export const getDevicesAPI = (params: GetDevicesAPIParams) => {
  return axiosClient.get<ListResponse<Device>>('/devices', {params});
};

export const deleteDeviceAPI = (id: string) => {
  return axiosClient.delete<SuccessResponse>(`/devices/${id}`);
};

export const getDeviceStatisticAPI = () => {
  return axiosClient.get<ObjectResponse<DeviceStatistic>>(`/devices/statistic`);
};
