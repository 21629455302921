import {Area} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse} from './service-models';

interface GetAreasAPIParams {
  search?: string;
  page?: number;
  per_page?: number;
}

export const getAreasAPI = (params: GetAreasAPIParams) => {
  return axiosClient.get<ListResponse<Area>>('/areas', {params});
};

export const getAreaByIdAPI = (id: string) => {
  return axiosClient.get<ObjectResponse<Area>>(`/areas/${id}`);
};
