import {IconProps} from '~models';

const EyeIcon = ({fill = '#333', size = 15, ...props}: IconProps) => (
  <svg
    width={size}
    height={size * (13 / 15)}
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.406 8.374c1.525 2.388 3.744 3.763 6.094 3.763 2.344 0 4.562-1.375 6.087-3.763.663-1.037.663-2.719 0-3.75C12.062 2.237 9.844.862 7.5.862S2.937 2.237 1.406 4.624c-.662 1.038-.662 2.719 0 3.75zM2.2 5.13C3.55 3.012 5.48 1.8 7.5 1.8c2.019 0 3.95 1.212 5.3 3.33.469.732.469 2.007 0 2.738-1.35 2.119-3.281 3.331-5.3 3.331-2.019 0-3.95-1.212-5.3-3.331-.469-.731-.469-2.006 0-2.738zM4.794 6.5a2.707 2.707 0 005.412 0 2.707 2.707 0 00-5.412 0zm.937 0c0-.976.794-1.77 1.769-1.77.975 0 1.769.794 1.769 1.77 0 .974-.794 1.768-1.77 1.768A1.771 1.771 0 015.732 6.5z"
      fill={fill}
    />
  </svg>
);

export default EyeIcon;
