import {Attachment, Base} from './common';
import {Device} from './device';
import {Store} from './store';
import {User} from './user';

export interface MediaContent extends Base {
  description: string;
  slug: string;
  devices: Device[];
  stores: Store[];
  attachment: Attachment;
  playing: boolean;
  created_by: User;
  deleted_at: Date;
  total_devices_num: number;
  correct_plan_devices_num: number;
}

export interface MediaContentFilterParams {
  created_at?: string;
  search?: string;
  current_page?: number;
  per_page?: number;
}

export enum PlayingStatus {
  PLAYING = 'PLAYING',
  STOP = 'STOP',
}

export enum ConnectStatus {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}
