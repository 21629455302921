import {memo} from 'react';
import {Flex, Form} from 'antd';

import {showConfirmModal} from '~utils';
import {Input, Text, TextArea, UploadFile, Video} from '~components';

import {AddMediaContentForm, attachmentRules, nameRules} from '../../utils';
import './styles.scss';

interface Props {
  isPlaying: boolean;
  disabled: boolean;
}

const MediaContentInfo = ({isPlaying, disabled}: Props) => {
  const formInstance = Form.useFormInstance<AddMediaContentForm>();
  const attachment = Form.useWatch<File>('attachment', formInstance);

  const {setFieldValue, resetFields} = formInstance;

  const handlePreview = (file: any) => {
    showConfirmModal({
      title: 'Preview',
      footer: null,
      width: 700,
      content: <Video uri={file.link} file={file} />,
    });
  };

  return (
    <div className="fields">
      <Text className="fields__title">Thông tin nội dung</Text>
      <div className="fields__content">
        <Form.Item name="name" rules={nameRules}>
          <Input label="Tên nội dung" placeholder="Tên nội dung" required disabled={disabled} />
        </Form.Item>
        <Flex gap={16}>
          <Form.Item name="description">
            <TextArea label="Mô tả nội dung" placeholder="Nhập mô tả" disabled={disabled} />
          </Form.Item>
          <Form.Item name="attachment" rules={attachmentRules}>
            <UploadFile
              label={
                <Text>
                  Video <Text>{'(< 300MB)'}</Text>
                </Text>
              }
              file={attachment}
              disabled={isPlaying || disabled}
              required
              onChange={file => setFieldValue('attachment', file)}
              onRemove={() => resetFields(['attachment'])}
              onPreview={handlePreview}
            />
          </Form.Item>
        </Flex>
      </div>
    </div>
  );
};

export default memo(MediaContentInfo);
