import {memo, useEffect, useState} from 'react';
import {DefaultOptionType} from 'antd/es/select';

import {Select} from '~components';
import {useGetDataSelect} from '~hooks';
import {CommonObject, Store} from '~models';
import {getStoreByIdAPI, getStoresAPI} from '~services';
import {convertToOption} from '~utils';

interface Props {
  selectedStoreId?: string;
  setSearchParams: (values: CommonObject<string>) => void;
}

const SelectStore = ({selectedStoreId, setSearchParams}: Props) => {
  const [selectedStore, setSelectedStore] = useState<DefaultOptionType>();

  const {
    dataOptions: storeOptions,
    pagination,
    setSearchTerm,
    setPagination,
    handleEndReached,
    handleSearchChange,
  } = useGetDataSelect<Store>(getStoresAPI);

  const handleSelect = (store: DefaultOptionType) => {
    setSearchParams({store: String(store.value)});
    setSelectedStore(store);
  };

  const handleClear = () => {
    setSearchTerm('');
    setPagination({...pagination, current_page: 1});
    setSelectedStore(undefined);
    setSearchParams({store: ''});
  };

  useEffect(() => {
    if (selectedStoreId && !selectedStore) {
      getStoreByIdAPI(selectedStoreId).then(response => {
        const data = response.data.data;
        setSelectedStore(convertToOption(data));
      });
    }
  }, [selectedStore, selectedStoreId]);

  return (
    <Select
      placeholder="---Chọn cửa hàng---"
      label="Cửa hàng"
      horizontal
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      value={selectedStore}
      options={storeOptions}
      onSearch={handleSearchChange}
      onSelect={handleSelect}
      onEndReached={handleEndReached}
      onClear={handleClear}
    />
  );
};

export default memo(SelectStore);
