import {memo, useEffect, useState} from 'react';
import {DefaultOptionType} from 'antd/es/select';

import {Select} from '~components';
import {useGetDataSelect} from '~hooks';
import {CommonObject, Area} from '~models';
import {convertToOption} from '~utils';
import {getAreaByIdAPI, getAreasAPI} from '~services';

interface Props {
  selectedAreaId?: string;
  setSearchParams: (values: CommonObject<string>) => void;
}

const SelectArea = ({selectedAreaId, setSearchParams}: Props) => {
  const [selectedArea, setSelectedArea] = useState<DefaultOptionType>();

  const {
    dataOptions: areaOptions,
    pagination,
    setSearchTerm,
    setPagination,
    handleEndReached,
    handleSearchChange,
  } = useGetDataSelect<Area>(getAreasAPI);

  const handleSelect = (area: DefaultOptionType) => {
    setSearchParams({area: String(area.value)});
    setSelectedArea(area);
  };

  const handleClear = () => {
    setSearchTerm('');
    setPagination({...pagination, current_page: 1});
    setSelectedArea(undefined);
    setSearchParams({area: ''});
  };

  useEffect(() => {
    if (selectedAreaId && !selectedArea) {
      getAreaByIdAPI(selectedAreaId).then(response => {
        const data = response.data.data;
        setSelectedArea(convertToOption(data));
      });
    }
  }, [selectedArea, selectedAreaId]);

  return (
    <Select
      placeholder="---Chọn vùng---"
      label="Vùng"
      horizontal
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      value={selectedArea}
      options={areaOptions}
      onSearch={handleSearchChange}
      onSelect={handleSelect}
      onEndReached={handleEndReached}
      onClear={handleClear}
    />
  );
};

export default memo(SelectArea);
