import {Flex} from 'antd';

import {Select, SelectArea} from '~components';
import {connectStatusOptions, playingStatusOptions} from '~variables';
import {DeviceFilterParams} from '~models';

import SelectStore from '../SelectStore';
import SelectMediaContents from '../SelectMediaContents';
import './styles.scss';

interface Props {
  searchParams: DeviceFilterParams;
  setSearchParams: (values: DeviceFilterParams) => void;
}

const DeviceFilter = ({searchParams, setSearchParams}: Props) => {
  return (
    <Flex className="device-filter" wrap="wrap" gap={24}>
      <SelectStore selectedStoreId={searchParams.store} setSearchParams={setSearchParams} />
      <Select
        label="Kết nối"
        placeholder="---Chọn kết nối---"
        horizontal
        allowClear
        value={searchParams.connect}
        options={connectStatusOptions}
        onChange={connect => setSearchParams({connect})}
      />
      <Select
        label="Trạng thái"
        placeholder="---Chọn trạng thái---"
        horizontal
        allowClear
        value={searchParams.status}
        options={playingStatusOptions}
        onChange={status => setSearchParams({status})}
      />
      <SelectMediaContents
        selectedMediaContentId={searchParams.must_play_plan}
        setSearchParams={setSearchParams}
      />
      <SelectArea selectedAreaId={searchParams.area} setSearchParams={setSearchParams} />
    </Flex>
  );
};

export default DeviceFilter;
