import {memo, useEffect, useState} from 'react';
import {DefaultOptionType} from 'antd/es/select';

import {Select} from '~components';
import {useGetDataSelect} from '~hooks';
import {DeviceFilterParams, MediaContent} from '~models';
import {getMediaContentByIdAPI, getMediaContentsAPI} from '~services';
import {convertToOption} from '~utils';

interface Props {
  selectedMediaContentId?: string;
  setSearchParams: (values: DeviceFilterParams) => void;
}

const SelectMediaContents = ({selectedMediaContentId, setSearchParams}: Props) => {
  const [selectedMediaContent, setSelectedMediaContent] = useState<DefaultOptionType>();

  const {
    dataOptions: mediaContentOptions,
    pagination,
    setSearchTerm,
    setPagination,
    handleSearchChange,
    handleEndReached,
  } = useGetDataSelect<MediaContent>(getMediaContentsAPI);

  const handleSelect = (mediaContent: DefaultOptionType) => {
    setSearchParams({must_play_plan: String(mediaContent.value)});
    setSelectedMediaContent(mediaContent);
  };

  const handleClear = () => {
    setSearchTerm('');
    setPagination({...pagination, current_page: 1});
    setSelectedMediaContent(undefined);
    setSearchParams({must_play_plan: ''});
  };

  useEffect(() => {
    if (selectedMediaContentId && !selectedMediaContent) {
      getMediaContentByIdAPI(selectedMediaContentId).then(response => {
        const data = response.data.data;
        setSelectedMediaContent(convertToOption(data));
      });
    }
  }, [selectedMediaContent, selectedMediaContentId]);

  return (
    <Select
      placeholder="---Chọn nội dung---"
      label="Nội dung cần phát"
      horizontal
      showSearch
      allowClear
      labelInValue
      filterOption={false}
      value={selectedMediaContent}
      options={mediaContentOptions}
      onSearch={handleSearchChange}
      onSelect={handleSelect}
      onEndReached={handleEndReached}
      onClear={handleClear}
    />
  );
};

export default memo(SelectMediaContents);
