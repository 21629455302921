import {ChangeEvent, memo, useCallback, useEffect, useMemo} from 'react';
import {Form} from 'antd';

import {Device, DeviceFilterParams} from '~models';
import {Table} from '~components';
import {navigate} from '~routes';
import {getDevicesThunk} from '~reducers';
import {useDebounceFunction, useGetData, useMemoLocation, useTabChange} from '~hooks';

import DeviceTableHeader from './DeviceTableHeader';
import {AddMediaContentForm, getDeviceColumns} from '../../utils';
import './styles.scss';

interface Props {
  disabled: boolean;
}

const SelectDevices = ({disabled}: Props) => {
  const {
    isLoading,
    data: devices,
    searchParams,
    pagination,
    setSearchParams,
  } = useGetData<Device, DeviceFilterParams>(getDevicesThunk);

  const {state} = useMemoLocation();
  const formInstance = Form.useFormInstance<AddMediaContentForm>();
  const selectedDevices = Form.useWatch<Device[]>('devices');
  const memoSelectedDevices = useMemo(() => selectedDevices ?? [], [selectedDevices]);

  useTabChange(
    useCallback(() => {
      navigate(null, {
        replace: true,
        state: {
          cachedDevices: memoSelectedDevices,
        },
      });
    }, [memoSelectedDevices]),
  );

  const {setFieldValue} = formInstance;
  const selectedDeviceIds = memoSelectedDevices.map(({id}) => id);

  const handleSelectRow = (record: Device, selected: boolean) => {
    const newSelectedDevices = selected
      ? memoSelectedDevices.concat(record)
      : memoSelectedDevices.filter(d => d.id !== record.id);
    setFieldValue('devices', newSelectedDevices);
  };

  const handleSelectAll = (selected: boolean, _: unknown, changeRows: Device[]) => {
    const newSelectedDevices = selected
      ? memoSelectedDevices.concat(changeRows)
      : memoSelectedDevices.filter(d => !changeRows.some(r => r.id === d.id));
    setFieldValue('devices', newSelectedDevices);
  };

  const handleSearchChange = useDebounceFunction<ChangeEvent<HTMLInputElement>>(event => {
    setSearchParams({search: event.target.value});
  });

  const columns = useMemo(() => {
    return getDeviceColumns({searchTerm: searchParams.search});
  }, [searchParams.search]);

  useEffect(() => {
    if (state?.cachedDevices) {
      setFieldValue('devices', state.cachedDevices);
    }
  }, []);

  return (
    <div className="devices">
      <DeviceTableHeader
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        onSearchChange={handleSearchChange}
      />
      <Table
        className="devices__table"
        emptyText="Danh sách trống"
        showScroll={false}
        loading={isLoading}
        columns={columns}
        dataSource={devices}
        rowSelection={{
          checkStrictly: false,
          selectedRowKeys: selectedDeviceIds,
          onSelect: handleSelectRow,
          onSelectAll: handleSelectAll,
          getCheckboxProps: () => ({disabled}),
        }}
        pagination={{
          from: pagination.from,
          to: pagination.to,
          total: pagination.total,
          current: pagination.current_page,
          pageSize: pagination.per_page,
          onChange: current_page => setSearchParams({current_page}),
          onShowSizeChange: (_, per_page) => setSearchParams({per_page, current_page: 1}),
        }}
      />
    </div>
  );
};

const MemorizedSelectDevices = (props: Props) => (
  <Form.Item name="devices">
    <SelectDevices {...props} />
  </Form.Item>
);

export default memo(MemorizedSelectDevices);
