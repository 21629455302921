import {Store} from '~models';

import {axiosClient} from './axios-client';
import {ListResponse, ObjectResponse} from './service-models';

interface GetStoresAPIParams {
  search?: string;
  page?: number;
  per_page?: number;
}

export const getStoresAPI = (params: GetStoresAPIParams) => {
  return axiosClient.get<ListResponse<Store>>('/stores', {params});
};

export const getStoreByIdAPI = (id: string) => {
  return axiosClient.get<ObjectResponse<Store>>(`/stores/${id}`);
};
