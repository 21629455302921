import {ChangeEventHandler, ReactNode} from 'react';
import {Flex} from 'antd';
import {CloseCircleFilled} from '@ant-design/icons';

import {EyeIcon, PlusIcon, VideoIcon} from '~assets';
import {classNames} from '~utils';

import Text from '../Text';
import './styles.scss';

interface Props {
  className?: string;
  label?: ReactNode;
  errorMsg?: string;
  required?: boolean;
  disabled?: boolean;
  file: File;
  onChange: (file: File) => void;
  onRemove: () => void;
  onPreview: (file: File) => void;
}

const UploadFile = ({
  className,
  label,
  errorMsg,
  required,
  disabled,
  file,
  onChange,
  onRemove,
  onPreview,
}: Props) => {
  const handleFileChange: ChangeEventHandler<HTMLInputElement> = event => {
    if (event.target.files) {
      onChange(event.target.files[0]);
    }
  };

  return (
    <div
      className={classNames(`upload-file ${className}`, {
        'upload-file--disabled': disabled,
        'upload-file--has-file': file,
      })}>
      {label && (
        <div className="file-label">
          <Text className="file-label__text">{label}</Text>
          {required && <Text className="file-label__required">*</Text>}
        </div>
      )}
      <div className="file-main">
        <input
          className="file-main__input"
          type="file"
          accept="video/mp4"
          id="file"
          onChange={handleFileChange}
          disabled={!!file}
        />
        <label className="file-main__label" htmlFor="file">
          {file ? (
            <Flex vertical align="center" gap={10}>
              <VideoIcon fill={disabled ? 'grey' : undefined} />
              <Text className="file-main__text">{file.name}</Text>
            </Flex>
          ) : (
            <Flex vertical align="center" gap={10}>
              <PlusIcon fill={disabled ? 'grey' : undefined} />
              <Text className="file-main__text">Tải lên</Text>
            </Flex>
          )}
        </label>
        {file && <CloseCircleFilled className="file-main__icon" onClick={onRemove} />}
        {file && (
          <Flex className="file-main__preview" align="center" justify="center">
            <EyeIcon
              size={26}
              fill="#fff"
              className="file-main__preview-icon"
              onClick={() => onPreview(file)}
            />
          </Flex>
        )}
      </div>
      {errorMsg && <Text className="upload-file__error">{errorMsg}</Text>}
    </div>
  );
};

export default UploadFile;
