import {ColumnsType} from 'antd/es/table';
import Highlighter from 'react-highlight-words';

import {Device, Store} from '~models';
import {getSlug} from '~utils';

interface GetColumns {
  searchTerm?: string;
}

export const getDeviceColumns = ({searchTerm}: GetColumns): ColumnsType<Device> => {
  const renderStore = (store: Store) => (
    <Highlighter searchWords={[`${searchTerm}`]} textToHighlight={store.name} sanitize={getSlug} />
  );

  return [
    {
      title: 'Tên thiết bị',
      dataIndex: 'device_name',
      key: 'device_name',
    },
    {
      title: 'Cửa hàng',
      dataIndex: 'store',
      key: 'store',
      render: renderStore,
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'store',
      key: 'address',
      render: store => store?.address,
    },
  ];
};
